import React from "react"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"
import FedRAMP from "../../images/fedRAMP.png"
import "../PrivacyPolicy/Policy.scss"

const DomeCookiePolicy: React.FC = () => {
  return (
    <main className="w-full body-policy">
      <section className="flex justify-center bg-blueShade1">
        <article className="flex flex-col items-center max-w-700 xl-mobile:px-8">
          <TextTypography
            className="my-12 text-4xl sm:text-6xl"
            fontStyle={FontStyle.BOLD}
          >
            Dome Compass, Inc.
          </TextTypography>
          <div className="flex flex-col items-center mb-6">
            <img src={FedRAMP} alt="fedRAMP" className="w-40 mb-3 rounded-16" />
            <TextTypography
              className="text-2xl font-semibold"
              color={TextColor.SECONDARY}
            >
              FedRAMP Compliant
            </TextTypography>
          </div>
          <>
            <div>
              <div className="text-center">
                <strong>
                  <span style={{ fontSize: 26 }}>
                    <span className="title-policy">COOKIE POLICY</span>
                  </span>
                </strong>
              </div>
              <div>
                <br />
              </div>
              <div className={"text-center"}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <span className="subtitle-policy">
                        Last updated July 19, 2022
                      </span>
                    </span>
                  </strong>
                </span>
              </div>
              <div>
                <br />
              </div>
              <div>
                <br />
              </div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span className="body-text-policy">
                      This Cookie Policy explains how __________ (&quot;
                      <strong>Company</strong>&quot;
                      {", "} &quot;<strong>we</strong>&quot;
                      {", "}
                      &quot;<strong>us</strong>&quot;
                      {", and "}
                      &quot;<strong>our</strong>&quot;) uses cookies and similar
                      technologies to recognize you when you visit our websites
                      at{" "}
                      <a
                        href="https://staging.domecompass.com"
                        target="_blank"
                        className="link-policy"
                        rel="noreferrer"
                      >
                        https://staging.domecompass.com
                      </a>
                      , (&quot;<strong>{"Websites"}</strong>&quot; ). It
                      explains what these technologies are and why we use them,
                      as well as your rights to control our use of them.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span className="body-text-policy">
                      In some cases we may use cookies to collect personal
                      information, or that becomes personal information if we
                      combine it with other information.{" "}
                    </span>

                    <span className="body-text-policy" />
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                    <strong>
                      <span className="heading-one-policy">
                        What are cookies?
                      </span>
                    </strong>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span className="body-text-policy">
                      Cookies are small data files that are placed on your
                      computer or mobile device when you visit a website.
                      Cookies are widely used by website owners in order to make
                      their websites work, or to work more efficiently, as well
                      as to provide reporting information.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span className="body-text-policy">
                      Cookies set by the website owner (in this case,
                      __________) are called &quot;{"first party cookies"}
                      &quot;. Cookies set by parties other than the website
                      owner are called &quot;{"third party cookies"}&quot;.
                      Third party cookies enable third party features or
                      functionality to be provided on or through the website
                      (e.g. like advertising, interactive content and
                      analytics). The parties that set these third party cookies
                      can recognize your computer both when it visits the
                      website in question and also when it visits certain other
                      websites.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                    <strong>
                      <span className="heading-one-policy">
                        Why do we use cookies?
                      </span>
                    </strong>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span className="body-text-policy">
                      We use first and third party cookies for several reasons.
                      Some cookies are required for technical reasons in order
                      for our Websites to operate, and we refer to these as
                      &quot;{"essential"}&quot; or &quot;{"strictly necessary"}
                      &quot; cookies. Other cookies also enable us to track and
                      target the interests of our users to enhance the
                      experience on our Online Properties. Third parties serve
                      cookies through our Websites for advertising, analytics
                      and other purposes. This is described in more detail
                      below.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span className="body-text-policy">
                      The specific types of first and third party cookies served
                      through our Websites and the purposes they perform are
                      described below (please note that the specific cookies
                      served may vary depending on the specific Online
                      Properties you visit):
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                          <strong>
                            <span className="heading-one-policy">
                              How can I control cookies?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      You have the right to decide whether to accept or reject
                      cookies. You can exercise your cookie rights by setting
                      your preferences in the Cookie Consent Manager. The Cookie
                      Consent Manager allows you to select which categories of
                      cookies you accept or reject. Essential cookies cannot be
                      rejected as they are strictly necessary to provide you
                      with services.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      The Cookie Consent Manager can be found in the
                      notification banner and on our website. If you choose to
                      reject cookies, you may still use our website though your
                      access to some functionality and areas of our website may
                      be restricted. You may also set or amend your web browser
                      controls to accept or refuse cookies. As the means by
                      which you can refuse cookies through your web browser
                      controls vary from browser-to-browser, you should visit
                      your
                      {"browser's"} help menu for more information.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      In addition, most advertising networks offer you a way to
                      opt out of targeted advertising. If you would like to find
                      out more information, please visit&nbsp;
                    </span>
                  </span>
                  <span style={{ fontSize: 15, color: "rgb(48, 48, 241)" }}>
                    <span className="body-text-policy">
                      <a
                        className="link-policy"
                        href="https://www.aboutads.info/choices/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.aboutads.info/choices/
                      </a>
                    </span>
                  </span>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">&nbsp;or&nbsp;</span>
                  </span>
                  <span style={{ fontSize: 15, color: "rgb(48, 48, 241)" }}>
                    <span className="body-text-policy">
                      <a
                        className="link-policy"
                        data-fr-linked="true"
                        href="https://www.youronlinechoices.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.youronlinechoices.com
                      </a>
                    </span>
                  </span>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">.</span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      The specific types of first and third party cookies served
                      through our Websites and the purposes they perform are
                      described in the table below (please note that the
                      specific&nbsp;
                    </span>
                    <span className="body-text-policy">
                      cookies served may vary depending on the specific Online
                      Properties you visit):
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: 15 }} />
                <span
                  className="heading-two-policy"
                  style={{ color: "rgb(0, 0, 0)" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>
                      <u>
                        <br />
                        <br />
                        Essential website cookies:
                      </u>
                    </strong>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span className="body-text-policy">
                        These cookies are strictly necessary to provide you with
                        services available through our Websites and to use some
                        of its features, such as access to secure areas.
                      </span>
                    </span>
                  </span>
                </div>
                <p
                  style={{ fontSize: 15, lineHeight: "1.5", marginTop: "15px" }}
                >
                  <span className="body-text-policy" />
                </p>
                <section
                  style={{
                    width: "100%",
                    border: "1px solid rgb(230, 230, 230)",
                    margin: "0px 0px 10px",
                    borderRadius: 3,
                    textAlign: "left",
                  }}
                >
                  <div
                    className={"box"}
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 14,
                            lineHeight: "1.67",
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __tlbcpv
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 14,
                            lineHeight: "1.67",
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Used to record unique visitor views of the consent
                              banner.
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 14,
                            lineHeight: "1.67",
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              .termly.io
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 14,
                            lineHeight: "1.67",
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Termly{" "}
                              <a
                                href="https://termly.io/our-privacy-policy/"
                                className="light-blue-color-link"
                                target="_blank"
                                rel="noreferrer"
                              >
                                View Service Privacy Policy
                              </a>
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 14,
                            lineHeight: "1.67",
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 14,
                            lineHeight: "1.67",
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 14,
                            lineHeight: "1.67",
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              1 year
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
              <div />
              <div>
                <div style={{ lineHeight: "1.5" }}>
                  <span
                    className="heading-two-policy"
                    style={{ color: "rgb(0, 0, 0)" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <strong>
                        <u>
                          <br />
                          Analytics and customization cookies:
                        </u>
                      </strong>
                    </span>
                  </span>
                </div>
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: 15,
                        marginTop: "15px",
                      }}
                    >
                      <span className="body-text-policy">
                        These cookies collect information that is used either in
                        aggregate form to help us understand how our Websites
                        are being used or how effective our marketing campaigns
                        are, or to help us customize our Websites for you.
                      </span>
                    </span>
                  </span>
                </p>
                <div>
                  <span>
                    <section
                      style={{
                        width: "100%",
                        border: "1px solid #e6e6e6",
                        margin: "0 0 10px",
                        borderRadius: 3,
                        marginTop: "15px",
                      }}
                    >
                      <div
                        className={"box"}
                        style={{
                          padding: "8px 13px",
                          borderBottom: "1px solid #e6e6e6",
                        }}
                      >
                        <table>
                          <tbody className="box">
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Name:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  _gid
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: 0,
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Purpose:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  Keeps an entry of unique ID which is then used
                                  to come up with statistical data on website
                                  usage by visitors. It is a HTTP cookie type
                                  and expires after a browsing session.
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Provider:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  .staging.domecompass.com
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Service:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  Google Analytics{" "}
                                  <a
                                    className={"light-blue-color-link"}
                                    href="https://policies.google.com/technologies/ads"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View Service Privacy Policy
                                  </a>
                                  &nbsp;
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Country:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  United States
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Type:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  http_cookie
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Expires in:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  1 day
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>
                    <section
                      style={{
                        width: "100%",
                        border: "1px solid #e6e6e6",
                        margin: "0 0 10px",
                        borderRadius: 3,
                      }}
                    >
                      <div
                        style={{
                          padding: "8px 13px",
                          borderBottom: "1px solid #e6e6e6",
                        }}
                        className={"box"}
                      >
                        <table>
                          <tbody>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Name:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  _gat
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: 0,
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Purpose:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  Used to monitor number of Google Analytics
                                  server requests when using Google Tag Manager
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Provider:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  .staging.domecompass.com
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Service:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  Google Analytics{" "}
                                  <a
                                    href="https://policies.google.com/technologies/ads"
                                    className={"light-blue-color-link"}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View Service Privacy Policy
                                  </a>
                                  &nbsp;
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Country:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  United States
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Type:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  http_cookie
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Expires in:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  1 minute
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>
                    <section
                      style={{
                        width: "100%",
                        border: "1px solid #e6e6e6",
                        margin: "0 0 10px",
                        borderRadius: 3,
                      }}
                    >
                      <div
                        style={{
                          padding: "8px 13px",
                          borderBottom: "1px solid #e6e6e6",
                        }}
                        className={"box"}
                      >
                        <table>
                          <tbody>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Name:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  _ga
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: 0,
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Purpose:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  It records a particular ID used to come up
                                  with data about website usage by the user. It
                                  is a HTTP cookie that expires after 2 years.
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Provider:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  .staging.domecompass.com
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Service:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  Google Analytics{" "}
                                  <a
                                    href="https://policies.google.com/technologies/ads"
                                    className={"light-blue-color-link"}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View Service Privacy Policy
                                  </a>
                                  &nbsp;
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 12,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Country:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  United States
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Type:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  http_cookie
                                </span>
                              </td>
                            </tr>
                            <tr
                              style={{
                                fontFamily: "Roboto, Arial",
                                fontSize: 14,
                                lineHeight: "1.67",
                                margin: "0 0 8px",
                                verticalAlign: "top",
                              }}
                            >
                              <td
                                style={{
                                  textAlign: "right",
                                  color: "#19243c",
                                  minWidth: 80,
                                }}
                              >
                                Expires in:
                              </td>
                              <td
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8b93a7",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  1 year 11 months 29 days
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                    <strong>
                      <span className="heading-one-policy">
                        What about other tracking technologies, like web
                        beacons?
                      </span>
                    </strong>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span className="body-text-policy">
                      Cookies are not the only way&nbsp;
                    </span>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="body-text-policy">
                        to recognize or track visitors to a website. We may use
                        other, similar technologies from time to time, like web
                        beacons (sometimes called &quot;{"tracking pixels"}
                        &quot; or &quot;{"clear gifs"}&quot;). These are tiny
                        graphics files that contain a unique identifier that
                        enable us to recognize when someone has visited our
                        Websites or opened an e-mail including them . This
                        allows us, for example, to monitor&nbsp;
                      </span>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span className="body-text-policy">
                            the traffic patterns of users from one page within a
                            website to another, to deliver or communicate with
                            cookies, to understand whether you have come to the
                            website from an online advertisement displayed on a
                            third-party website, to improve site performance,
                            and to measure the success of e-mail marketing
                            campaigns. In many instances, these technologies are
                            reliant on cookies to function properly, and so
                            declining cookies will impair their functioning.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <strong>
                            <span className="heading-one-policy">
                              Do you use Flash cookies or Local Shared Objects?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span className="body-text-policy">
                    Websites may also use so-called &quot;{"Flash Cookies"}
                    &quot; (also known as Local Shared Objects or &quot;{"LSOs"}
                    &quot;) to, among other things, collect and store
                    information about your use of our services, fraud prevention
                    and for other site operations.
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span className="body-text-policy">
                    If you do not want Flash Cookies stored on your computer,
                    you can adjust the settings of your Flash player to block
                    Flash Cookies storage using the tools contained in the&nbsp;
                  </span>
                </span>
                <span className="body-text-policy">
                  <span style={{ color: "rgb(48, 48, 241)" }}>
                    <a
                      className="link-policy"
                      href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                      target="_BLANK"
                      rel="noreferrer"
                    >
                      Website Storage Settings Panel
                    </a>
                  </span>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    . You can also control Flash Cookies by going to the&nbsp;
                  </span>
                  <span style={{ color: "rgb(48, 48, 241)" }}>
                    <a
                      className="link-policy"
                      href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                      target="_BLANK"
                      rel="noreferrer"
                    >
                      Global Storage Settings Panel
                    </a>
                  </span>
                </span>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span className="body-text-policy">&nbsp;and&nbsp;</span>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      following the instructions (which may include instructions
                      that explain, for example, how to delete existing Flash
                      Cookies (referred to &quot;
                      {"information"}&quot; on the Macromedia site), how to
                      prevent Flash LSOs from being placed on your computer
                      without your being asked, and (for Flash Player 8 and
                      later) how to block Flash Cookies that are not being
                      delivered by the operator of the page you are on at the
                      time).
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      Please note that setting the Flash Player to restrict or
                      limit acceptance of Flash Cookies may reduce or impede the
                      functionality of some Flash applications, including,
                      potentially, Flash applications used in connection with
                      our services or online content.
                    </span>
                  </span>
                </span>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}></span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}
                            >
                              <strong>
                                <span className="heading-one-policy">
                                  Do you serve targeted advertising?
                                </span>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span className="body-text-policy">
                    Third parties may serve cookies on your computer or mobile
                    device to serve advertising through our Websites. These
                    companies may use information about your visits to this and
                    other websites in order to provide relevant advertisements
                    about goods and services that you may be interested in. They
                    may also employ technology that is used to measure the
                    effectiveness of advertisements. This can be accomplished by
                    them using cookies or web beacons to collect information
                    about your visits to this and other sites in order to
                    provide relevant advertisements about goods and services of
                    potential interest to you. The information collected through
                    this process does not enable us or them to identify your
                    name, contact details or other details that directly
                    identify you unless you choose to provide these.
                  </span>
                </span>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}></span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span className="heading-one-policy">
                        How often will you update this Cookie Policy?
                      </span>
                    </strong>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      We may update&nbsp;
                    </span>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="body-text-policy">
                        this Cookie Policy from time to time in order to
                        reflect, for example, changes to the cookies we use or
                        for other operational, legal or regulatory reasons.
                        Please therefore re-visit this Cookie Policy regularly
                        to stay informed about our use of cookies and related
                        technologies.
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="body-text-policy">
                        The date at the top of this Cookie Policy indicates when
                        it was last updated.
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span className="heading-one-policy">
                          Where can I get further information?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="body-text-policy">
                        If you have any questions about our use of cookies or
                        other technologies, please email us at{" "}
                        <a
                          href={`mailto:privacy@domecompass.com`}
                          target={"_blank"}
                          rel={"noreferrer noopener"}
                          className="text-2xl link-policy"
                        >
                          privacy@domecompass.com
                        </a>{" "}
                        or by post to:
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span className="body-text-policy">
                  <br />
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">Dome Compass, Inc.</span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span className="body-text-policy">
                    700 Pennsylvania Ave. SE
                  </span>

                  <span className="body-text-policy"></span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span className="body-text-policy">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    Washington, DC 20003
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span className="body-text-policy">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    United States
                  </span>
                </span>
              </div>
            </div>
          </>
        </article>
      </section>
    </main>
  )
}
export default DomeCookiePolicy
