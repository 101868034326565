import React from "react"
import AppFooter from "../components/AppFooter/AppFooter"
// import AppHeader from "../components/AppHeader/AppHeader"
import AppHeader from "../components/V2/AppHeader"
import DomeCookiePolicy from "../components/CookiePolicy/DomeCookiePolicy"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"

const CookiePolicy: React.FC = () => {
  return (
    <div className=" w-full bg-lightBackground">
      <FaviconHeader />
      <AppHeader />
      <DomeCookiePolicy />
      <AppFooter />
    </div>
  )
}
export default CookiePolicy
